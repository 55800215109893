//GAME CONSTRAINTS
$containerSize: 1024px;

//PRIMARY COLORS
$navColor: rgb(216, 191, 163);
$footColor: rgb(192, 168, 136);
$backgroundColor: rgb(240, 232, 214);
$containerColor: rgb(235, 212, 163);
$columnColor: rgb(224, 200, 148);
$menuColor: rgb(235, 214, 148, 0.5);

//BUTTON COLORS
$btnMainColor: rgb(218, 168, 77);
$btnMainHover: rgb(199, 150, 60);
$btnMainActive: rgb(173, 131, 52);
