@import "./variables.scss";

body {
	background-color: $backgroundColor;
	color:rgb(0, 0, 0);
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .container {
		background-color: $containerColor;
    //margin-top: 80px;
		max-width: $containerSize;
		padding: 1rem;
		@media screen and (max-width: 991px) {
				margin-top: 64px;
		}
  }

	.home-input-form {
		position: relative;
	}
	.home-input-error {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		width: 90%;
	}

	.mb-4-5 {
		margin-bottom: 2.25rem !important;
	}

	.row {
		background-color: $columnColor;
		margin-left: 0;
		margin-right: 0;
		padding: 1rem;
	}

	.card {
		background-color: $menuColor;
	}

	.battleText {
		color: red;
		position: absolute;
		left: 100%;
		animation: fadeOut 0.25s linear 0.5s forwards;
	}
	.battleSpan {
		width: 100%;
		transition: width 0.6s ease;
		position: absolute;
	}
	.progress-bar {
		border-radius: 0.25rem;
	}
	.progress {
		background-color:rgba(230, 230, 230, 0.75);
		border: 1px solid rgba(182, 182, 182, 0.75);
	}

	@keyframes fadeOut {
		0% {opacity: 1;}
		100% {opacity: 0;} 
	}

	.btn-main {
		color: black;
		font-weight: 500;
		background-color: $btnMainColor;
		border: 1px solid rgba(0, 0, 0, 0.25);
		&:hover {
			background-color: $btnMainHover;
		}
		&:active {
			background-color: $btnMainActive;
		}
		&:focus {
			box-shadow: none;
		}
	}

 	footer {
		background-color: $footColor;
		width: 100%;
		border-top: 2px solid black;
		text-align: center;
		padding: 15px;
		margin-top: auto;
	}
}

//NAVIGATION CSS
.navigation {
  nav {
		//backdrop-filter: blur(3px);
		margin: 0 auto;
		min-height: 80px;
		border-bottom: 2px solid black;
		width: 100%;
		max-width: $containerSize;
	}

  .nav-logo {
		background-image: url("../../public/favicon.ico");
		background-size: 32px 32px;
		margin-top: 2px;
		margin-right: 16px;
		width: 32px;
		height: 32px;
	}

	.navbar {
		background-color: $navColor !important;
		font-weight: bold;
		font-size: 1.25rem;
		padding: 0.5rem;
		.nav-item {
			padding: 0.5rem 0;
			.nav-link-active {
				text-decoration: underline;
			}
			.nav-link, .fab {
				color: rgb(0, 0, 0);
				&:hover {
					color: rgba(0,0,0,0.7);
				}
			}
		}
		.navbar-brand {
			color: black;
			font-size: 1.5rem;
		}
	}

  #navbarBlocker {
		display: none !important;
	}

	#navbarSupportedContent {
		background-color: unset;

		ul {
			width: 100%;
			justify-content: flex-start;
		}
		li:nth-child(n+4) {
			margin-left: auto;
		}
	}

	@media screen and (min-width: 992px) {
		min-height: 80px;
		border-bottom: 2px solid black;
		background-color: $navColor !important;
	}

  @media screen and (max-width: 991px) {
		nav {
			min-height: 55px;
			max-width: 100%;
		}
		.nav-logo {
			margin-top: 4px;
			margin-right: 0;
		}
		.navbar {
			.nav-item {
				font-size: 1rem;
				padding: 0;
				&:last-of-type {
					margin-bottom: 10px;
				}
			}
		}

		.navbar-brand {
			margin-right: 0;
		}

    .navbar-toggler {
			border-color: rgba(0, 0, 0, 1) !important;
			outline: none !important;
			&:focus {
				background-color: rgba(0,0,0,0.1);
			}
		}

    #navbarSupportedContent {
			.navbar-nav {
				margin-top: 5px;
			}

			li {
				text-align: center;
				font-size: 150%;

				&:nth-child(odd) {
					background-color: rgb(167, 167, 167);
				}
				&:nth-child(even) {
					background-color: rgb(139, 139, 139);
				}
			}
		}
  }
}
